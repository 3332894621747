<template>
  <Layout :tituloPagina="`Network | DHs ${router.nombre != '' ? ' de ' + router.nombre : ''}`">
    <div class="row">
      <div class="col-lg-5">
        <cmp-secciones seccion="dhs"></cmp-secciones>
      </div>
    </div>

    <div class="row">
      <div class="col-md-2 col-2 mb-3">
        <!-- <div class="form-check form-switch form-switch-lg mb-3">
          <input
            :checked="!todos"
            @change="
              todos = !todos,
              refrescarDhs()
            "
            class="form-check-input"
            type="checkbox"
            id="banderaTodos"
          />
          <label
            class="form-check-label"
            for="banderaTodos"
          >
            Activos
          </label>
        </div> -->
        <button
          class="btn btn-secondary"
          @click="mostrarMdlFiltros()"
        >
          <span class="d-none d-sm-block">
            <i class="mdi mdi-format-list-bulleted"></i>
            Filtros
          </span>
          <span class="d-block d-sm-none">
            <i class="mdi mdi-format-list-bulleted"></i>
          </span>
        </button>
      </div>
      <div class="col-md-8 col-8 mb-3">
        <filtrador
          v-on:dato-actualizado="
            dato = $event,
            refrescarDhs()
          "
          :datoini="dato"
          placeholder="Dato del DH"
        ></filtrador>
      </div>
      <div class="col-md-2 col-2 mb-3 text-right">
        <router-link :to="{ name: 'nuevoDh' }" class="btn btn-success">
          <span class="d-none d-sm-block">
            <i class="mdi mdi-plus-thick"></i>
            Nuevo
          </span>
          <span class="d-block d-sm-none">
            <i class="mdi mdi-plus-thick"></i>
          </span>
        </router-link>
      </div>
    </div>
    <div class="row">
      <div class="col-md-4 offset-md-8 text-right" style="padding-top: 10px;">
        <span class="badge bg-secondary bg-gradient font-size-12">
          {{nDhsEnPag}} dhs en página
        </span>
        <span class="badge bg-secondary bg-gradient font-size-12">
          {{nDhsTotales}} dhs en total
        </span>
      </div>
    </div>

    <br />

    <div class="card">
      <div class="card-body" style="min-height: 475px;">
        <div class="table-responsive">
          <table class="table table-hover table-nowrap" id="tabla-dhs">
            <thead>
              <tr>
                <th style="width: 90px"></th>
                <th style="width: 200px">Nombre de DH</th>
                <th style="width: 150px">MAC</th>
                <th>Cliente</th>
                <th style="width: 110px">Estado</th>
                <th style=" width: 180px">Acciones</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="dh in dhs" :key="dh.id">
                <td>
                  <img
                    :src="
                      API + '/dhs/' + dh.id +
                      '/foto?tipo_foto=64x64&_tk=' + tk
                    "
                    v-if="dh.identificador_modelo != null"
                    class="icondh"
                  />
                  <img :src="dhDefault" v-if="dh.identificador_modelo == null" class="icondh rounded-circle"/>
                </td>
                <td class="fw-semibold">
                  {{ dh.nombre }}
                </td>
                <td>{{ dh.mac_wan }}</td>
                <td>
                  <router-link
                    :to="{
                      name: 'tableroCliente',
                      params: { id_cliente: dh.id_cliente }
                    }"
                    v-if="
                      dh.nombre_cliente != undefined && dh.id_cliente != null
                    "
                  >
                    {{ dh.nombre_cliente }}
                  </router-link>
                </td>
                <td>
                  <span
                    class="badge bg-gradient font-size-13"
                    :class="{
                      'bg-success': dh.estado == 'funcional',
                      'bg-warning': dh.estado == 'en reparación',
                      'bg-danger': dh.estado == 'descompuesto'
                    }"
                  >
                    {{ dh.estado }}
                  </span>
                </td>

                <td>
                  <router-link
                    :to="{ name: 'edicionDh', params: { id: dh.id } }"
                    class="btn btn-warning btn-sm"
                  >
                    <i class="mdi mdi-content-save-edit-outline"></i>
                    Editar
                  </router-link>

                  <button
                    class="btn btn-danger btn-sm"
                    @click="preguntaEliminarDh(dh)"
                  >
                    <i class="mdi mdi-trash-can-outline"></i>
                    Eliminar
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

    <div class="text-center">
      <paginador
        :pag="pagina"
        :totpag="ultima_pagina"
        :epp="por_pagina"
        v-on:cargar-pagina="cargarPagina"
        v-on:epp-actualizado="
          por_pagina = $event,
          refrescarDhs()
        "
      ></paginador>
    </div>
    <br>
    <MdlFiltros ref="mdlFiltrosDhs" :valoresInicialesFiltros="filtros" @filtros-actualizados-dhs="actualizarFiltros($event)" ></MdlFiltros>
  </Layout>
</template>

<script>
import Layout from '@/views/layouts/main'
import API from '@/API.js'
import Paginador from '@/components/Paginador.vue'
import Filtrador from '@/components/Filtrador.vue'
import CmpSecciones from './CmpSecciones.vue'
import DhSrv from '@/services/DhSrv.js'
import DhDefault from '@/assets/img/dh-default.jpg'
import MdlFiltros from "./MdlFiltros.vue"

import Swal from 'sweetalert2'

export default {
  name: 'ListadoDhs',
  components: { Layout, CmpSecciones, Paginador, Filtrador, MdlFiltros },
  data() {
    return {
      tk: localStorage.getItem('argusblack.token'),
      baseUrl: window.location.origin,
      dhDefault: DhDefault,
      API: API,
      dh: {},
      dhs: [],
      dato: '',
      pagina: 1,
      por_pagina: parseInt(localStorage.getItem('argusblack.listadoDhs.por_pagina') || 10),

      filtros: {
        funcionales: false,
        en_reparacion: false,
        descompuestos: false
      },

      total_dhs: 0,
      ultima_pagina: 1,
      router: { nombre: '' },
      nDhsEnPag:0,
      nDhsTotales:0
    }
  },

  created: function() {
    var self = this

    if (this.$route.query.dato) this.dato = this.$route.query.dato
    if (this.$route.query.pagina) this.pagina = parseInt(this.$route.query.pagina)
    if (this.$route.query.por_pagina) this.por_pagina = parseInt(this.$route.query.por_pagina)
    if (this.$route.query.funcionales) this.filtros.funcionales = this.$route.query.funcionales == 'true' ? true : false
    if (this.$route.query.en_reparacion) this.filtros.en_reparacion = this.$route.query.en_reparacion == 'true' ? true : false
    if (this.$route.query.descompuestos) this.filtros.descompuestos = this.$route.query.descompuestos == 'true' ? true : false

    // Control de eventos
    self.inicio()
  },

  methods: {
    actualizarFiltros(filtros) {
      Object.assign(this.filtros, filtros)
      iu.spinner.mostrar('#tabla-dhs')
      this.refrescarDhs()
    },
    cargarPagina: function(n) {
      var self = this
      self.pagina = n
      self.refrescarDhs()
    },

    inicio: function() {
      var self = this

      // Cargas iniciales
      this.refrescarDhs()
    },

    mostrarMdlFiltros() {
      let self = this
      self.$refs.mdlFiltrosDhs.mostrar()
    },


    preguntaEliminarDh: function(dh) {
      var self = this

      const swal = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-success me-1",
          cancelButton: "btn btn-danger"
        },
        buttonsStyling: false
      });

      swal.fire({
        title: "Eliminación del DH",
        html: '¿Está seguro que desea eliminar el dh <strong>'+dh.nombre+'</strong> ?',
        icon: "warning",
        confirmButtonText: `<i class="mdi mdi-check-bold"></i> Si`,
        cancelButtonText: `<i class="mdi mdi-cancel"></i> No`,
        showCancelButton: true
      }).then(result => {
        if (result.value) {
          DhSrv.eliminar(dh.id).then(response => {
            swal.fire("Eliminado!", "Se eliminó correctamente", "success");
            self.refrescarDhs()
          }).catch(error => {
            let mensaje
            try {
              mensaje = error.response.data.message
            } catch (e) {
              mensaje = 'No se pudo eliminar el dh'
            }
            iu.msg.error(mensaje)
            console.log(error)
          })
        } else if ( result.dismiss === Swal.DismissReason.cancel ) {
          swal.fire("Cancelar", "Se canceló la operación correctamente", "error");
        }
      })
    },

    refrescarDhs: function(config) {
      var self = this

      iu.spinner.mostrar('#tabla-dhs')

      var query = {
        dato: this.dato,
        pagina: this.pagina,
        por_pagina: this.por_pagina,
        funcionales: this.filtros.funcionales,
        en_reparacion: this.filtros.en_reparacion,
        descompuestos: this.filtros.descompuestos
      }

      this.$router.replace({ query: query }).catch(error=>{})

      config = config || {}
      if (config.pagina == undefined) config.pagina = self.pagina
      if (config.por_pagina == undefined) config.por_pagina = self.por_pagina
      if (config.dato == undefined && self.dato != '') config.dato = self.dato
      if (self.filtros.descompuestos == true) config.con_descompuestos = true // Solo los que están en operación

      var filtros = []
      if( self.filtros.funcionales == true ) filtros.push('funcionales')
      if( self.filtros.en_reparacion == true ) filtros.push('en_reparacion')
      if( self.filtros.descompuestos == true ) filtros.push('descompuestos')
      config.filtros = filtros
      
      DhSrv.vdhs1JSON(config).then(response => {
        localStorage.setItem('argusblack.listadoDhs.por_pagina',self.por_pagina)
        let res = response.data
        self.dhs = res.dhs
        self.pagina = res.pagina
        self.ultima_pagina = res.ultima_pagina
        self.nDhsEnPag = res.nDhsEnPag
        self.nDhsTotales = res.total

        if (self.ultima_pagina < self.pagina) {
          self.pagina = self.ultima_pagina
          self.refrescarDhs()
        }
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudieron cargar los DHs'
        }
        iu.msg.error(mensaje)
        console.log(error)
      }).finally(() => {
        iu.spinner.ocultar('#tabla-dhs')
      })
    }
  },
  mounted() {
    iu.spinner.mostrar('#tabla-dhs')
  }
}
</script>

<style scoped>
.icondh{
  width:30px;
  height:30px;
  border-radius: 3px 3px 3px 3px;
  -moz-border-radius: 3px 3px 3px 3px;
  -webkit-border-radius: 3px 3px 3px 3px;
  border: 0px solid #000000;
  margin-right: 5px;
}
</style>