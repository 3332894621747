<template>
  <div class="modal fade" ref="mdlFiltrosDhs" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" role="dialog"  aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-xl" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">
            Filtros
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <p>
            Da click sobre el filtro que desas utilizar en el listado de dispositivos de hogar
          </p>
          <div>
            <div class="form-check form-switch mb-3 form-switch-lg" style="display: inline-block;">
              <input
                :checked="filtros.funcionales"
                @change="
                  filtros.funcionales = !filtros.funcionales,
                  filtrosActualizados()
                "
                class="form-check-input"
                type="checkbox"
                id="funcionales"
              />
              <label class="form-check-label" for="funcionales">Funcionales</label>
            </div>
            &nbsp; &nbsp; 
            <div class="form-check form-switch mb-3 form-switch-lg" style="display: inline-block;">
              <input
                :checked="filtros.en_reparacion"
                @change="
                  filtros.en_reparacion = !filtros.en_reparacion,
                  filtrosActualizados()
                "
                class="form-check-input"
                type="checkbox"
                id="en_reparacion"
              />
              <label class="form-check-label" for="en_reparacion">En reparación</label>
            </div>
            &nbsp; &nbsp; 
            <div class="form-check form-switch mb-3 form-switch-lg" style="display: inline-block;">
              <input
                :checked="filtros.descompuestos"
                @change="
                  filtros.descompuestos = !filtros.descompuestos,
                  filtrosActualizados()
                "
                class="form-check-input"
                type="checkbox"
                id="descompuestos"
              >
              <label class="form-check-label" for="descompuestos">Descompuestos</label>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-light"
            data-bs-dismiss="modal"
          >
            Cerrar
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Modal from 'bootstrap/js/dist/modal';
export default {
  name: 'MdlFiltros',
  props: {
    valoresInicialesFiltros: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      filtros: {
        funcionales: false,
        en_reparacion: false,
        descompuestos: false
      }
    }
  },

  created: function() {
    var self = this
    // Actualización de los valores iniciales de los filtros
    if (self.valoresInicialesFiltros) {
      Object.assign(self.filtros, self.valoresInicialesFiltros)
    }
  },

  methods: {
    filtrosActualizados() {
      this.$emit('filtros-actualizados-dhs', this.filtros)
    },
    mostrar() {
      const modal = new Modal(this.$refs.mdlFiltrosDhs)
      modal.show()
    }
  }
}
</script>

<style scoped>

</style>