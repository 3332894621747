import axios from 'axios'
import API from '@/API.js'

const apiClient = axios.create({
  baseURL: `${API}/dhs`,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Authorization: localStorage.getItem('argusblack.token')
  }
})

// Actualización del token de autorización
document.addEventListener('actualizar:token', function(e) {
  apiClient.defaults.headers['Authorization'] = e.detail
})

// Eliminación del token de autorización
document.addEventListener('eliminar:token', function() {
  apiClient.defaults.headers['Authorization'] = null
})

export default {
  actualizar(dh) {
    return apiClient.post(`${dh.id}`, dh)
  },

  dhJSON(idDh) {
    return apiClient.get(`${idDh}.json`)
  },

  dhsJSON(params) {
    params = params || {}
    return apiClient.get(`${API}/dhs.json`, { params: params })
  },

  disponiblesParaContratoJSON(params) {
    params = params || {}
    return apiClient.get('disponibles-para-contrato.json', { params: params })
  },

  eliminar(idDh) {
    return apiClient.delete(`${idDh}`)
  },

  guardar(dh) {
    return apiClient.post('', dh)
  },

  vdhs1JSON(params) {
    params = params || {}
    return apiClient.get(`${API}/vdhs1.json`, { params: params })
  }
}
